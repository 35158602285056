import { Typography } from '@@/components/Elements';
import { Link } from 'react-router-dom';

import ImgLockedAccount from '@/assets/locked-account.svg';

type LockedAccountProps = {
	type?: string;
};

export const LockedAccount = (props: LockedAccountProps) => {
	const { type } = props;
	return (
		<div className='text-center'>
			<img src={ImgLockedAccount} alt='Padlock' className='w-[132px] h-auto mx-auto' />
			<Typography variant='display2' className='py-6'>
				Oops!
			</Typography>
			<Typography variant='title2' className='text-secondaryTextDark font-semibold'>
				Due to multiple failed attempts, your account has been temporarily locked. Please
				try again later to{' '}
				<Link reloadDocument={true} to='/' className='text-primaryBrand'>
					log in
				</Link>
				{type === 'login' ? '.' : ' and enable Multi-Factor Authentication (MFA).'}
			</Typography>
		</div>
	);
};
