export const AUTH = {
	APIS: {
		LOGIN: 'v2/auth/login/',
		LOGOUT: 'v1/auth/logout/',
		RESET_PASSWORD: 'v1/auth/password_reset/',
		VALIDATE_TOKEN: 'v1/auth/password_reset/validate_token/',
		NEW_PASSWORD_CONFIRMATION: 'v1/auth/password_reset/confirm/',
		FETCH_TERMS: 'v1/terms/active/',
	},
	ROUTES: {
		FORGOT_PASSWORD: {
			TITLE: 'Forgot Password?',
			BODY: `Enter the email address associated with your account and we’ll send you a link
			to reset your password.`,
		},
		LOGIN: {
			TITLE: 'Log in to your account',
		},
		RESET_PASSWORD: {
			TITLE: 'Password Reset',
			BODY: 'Enter your new password',
			FORM: {
				ERROR: {
					TITLE: 'The link has expired.',
					BODY: 'Please contact your system admin.',
				},
			},
			TERMS_AND_CONDITIONS: {
				ERROR: {
					BODY: 'Please agree with Umed Terms.',
				},
			},
		},
	},
	COMPONENTS: {
		LOGIN: {
			ERROR_ALERT: {
				DESCRIPTION: 'Sorry, login failed. Please try again.',
			},
			SUCCESS_NOTIFICATION: {
				TITLE: 'Success',
				BODY: 'You have successfully logged in',
				TIMEOUT_LENGTH: 8000,
			},
			FORM: {
				FORGOT_PASSWORD_LINK: {
					LABEL: 'Forgot password?',
				},
				SUBMIT_BUTTON: {
					LABEL: 'Log in',
				},
			},
		},
		FORGOTTEN_PASSWORD: {
			BODY: {
				PARAGRAPH: {
					A: `We’ve just sent you an email`,
					B: 'We have sent an e-mail to',
					C: 'Please follow the link in the email to reset your password',
				},
				RETURN_TO_LOGIN_LINK: 'Return to sign in',
			},
			FORM: {
				SIGN_IN_LINK: {
					LABEL: 'Sign in to your account?',
				},
				SUBMIT_BUTTON: {
					LABEL: 'Reset password',
				},
			},
		},
		PASSWORD_CHECKER: {
			LETTER_MATCH: '2 letters, of which',
			UPPERCASE: '1 should be uppercase',
			LOWER_CASE: '1 should be lowercase',
			NUMBER: '1 number',
			SYMBOLS: '1 symbol (! @ £ $ ^ & % # * + _ -)',
			MINIMUM_CHARACTERS: 'a minimum length of 10 characters',
		},
		RESET_PASSWORD: {
			SUCCESS_NOTIFICATION: {
				TITLE: 'Success',
				BODY: 'Your password was changed successfully. Please log in with your new password.',
				TIMEOUT_LENGTH: 8000,
			},
			FORM: {
				VALIDATION: {
					ERRORS: {
						PASSWORD_MINIMUM_REQUIREMENTS:
							'Password does not meet requirements. Please try again.',
						PASSWORD_REQUIRED: 'Password is required. Please try again.',
					},
				},
				SUBMIT_BUTTON: {
					LABEL: 'Reset password',
				},
			},
		},
		TERMS_AND_CONDITIONS: {
			CHECKBOX: {
				LABEL: 'I agree to the',
				LINK: 'Umed Terms',
			},
			MODAL: {
				TITLE: 'Terms to be accepted by Users of the uMed Platform',
				CLOSE_BUTTON: {
					LABEL: 'Close',
				},
			},
		},
	},
	MFA: {
		STORAGE_KEY: 'mfa_userConfirmedLogin',
		CONFIRMED_VALUE: 'isMfaAuthComplete',
		FEATURE_FLAG_NAME: 'mfa_enrolled',
	},
};
