import { Alert, Button, Typography } from '@@/components/Elements';
import {
	FormContainer as Form,
	FormActions,
	FormValues,
	InputField,
	VerificationCodeField,
} from '@@/components/Form';
import { CollapsibleSectionContainer } from '@@/components/Layout';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import IconSent from '@/assets/icon_sent.svg';
import { addNotification } from '@/components/Notifications/notificationsSlice';
import { baseApi } from '@/lib/rtkQuery/baseApi';

import { useLoginWithCodeMutation, useResendCodeMutation } from '../../api/authApi';
import { AUTH } from '../../consts';
import { LoginWithCodeRequest } from '../../types';
import { getAfterLoginRedirectUrl } from '../../utils';

import { LockedAccount } from './LockedAccount';
import { ResendCode } from './ResendCode';

const verificationCodeLength = 6;
const recoveryCodeLength = 12;

type MFALoginFormProps = {
	ephemeralToken: string;
	method: string | undefined;
};

export const MFALoginForm = (props: MFALoginFormProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loginWithCode, { isSuccess, isError, error }] = useLoginWithCodeMutation();
	const [isVerificationError, setIsVerificationError] = useState(false);
	const [codeResentConfirm, setCodeResentConfirm] = useState(false);
	const [isRecoveryCodeLogin, setIsRecoveryCodeLogin] = useState(false);
	const { ephemeralToken, method } = props;

	const initialValues: FormValues = {
		code: '',
	};

	const codeLength = isRecoveryCodeLogin ? recoveryCodeLength : verificationCodeLength;
	const codeRegex = isRecoveryCodeLogin ? /^[a-zA-Z0-9]+$/ : /^[0-9]+$/;
	const schema = Yup.object().shape({
		code: Yup.string()
			.required(`${isRecoveryCodeLogin ? 'Recovery code' : 'Verification code'} is required`)
			.matches(
				codeRegex,
				`Code must only be ${isRecoveryCodeLogin ? 'numbers or letters' : 'numbers'}`
			)
			.min(codeLength, `Code must be exactly ${codeLength} digits`)
			.max(codeLength, `Code must be exactly ${codeLength} digits`),
	});

	useEffect(() => {
		if (isSuccess) {
			// user successfully logged in
			dispatch(
				addNotification({
					type: 'success',
					title: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.TITLE,
					message: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.BODY,
					timeoutLength: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.TIMEOUT_LENGTH,
				})
			);
			localStorage.setItem(AUTH.MFA.STORAGE_KEY, AUTH.MFA.CONFIRMED_VALUE);
			const redirectUrl = getAfterLoginRedirectUrl();
			if (redirectUrl.includes('http')) {
				// redirect to external URL
				window.location.replace(redirectUrl);
			} else {
				// go to index page
				dispatch(baseApi.util.invalidateTags(['session']));
				navigate('/');
			}
		}
	}, [dispatch, isSuccess, navigate]);

	if (isError && (error as FetchBaseQueryError).status === 429) {
		// too many requests, display error to prevent further requests
		return <LockedAccount type='login' />;
	}

	const methodText = method === 'email' ? 'email' : method === 'sms' ? 'phone number' : 'console';
	return (
		<>
			<Typography variant='display2' className='text-primaryBrand mb-2'>
				{AUTH.ROUTES.LOGIN.TITLE}
			</Typography>
			{isVerificationError ? (
				<div className='mb-8'>
					<Alert
						severity='error'
						title={`${isRecoveryCodeLogin ? 'Recovery' : 'Verification'} code error`}
					>
						{isRecoveryCodeLogin
							? 'The code is invalid. Please try again.'
							: 'The code is invalid or expired. Please try again or request a new code'}
					</Alert>
				</div>
			) : (
				codeResentConfirm && (
					<div className='mb-8'>
						<Alert
							severity='success'
							customIcon={<img src={IconSent} alt='sent' className='w-5 h-5' />}
						>
							New verification code has been sent.
						</Alert>
					</div>
				)
			)}
			<Typography variant='title4' className='mb-6'>
				Multi-Factor Authentication
			</Typography>
			{!isRecoveryCodeLogin && (
				<Typography variant='title4' className='mb-2 text-secondaryTextDark'>
					Please enter the 6-digit code we have sent to your {methodText}.
				</Typography>
			)}
			<Form
				initialValues={initialValues}
				onSubmit={async (
					values: FormValues,
					actions: FormActions & FormikHelpers<FormValues>
				) => {
					await loginWithCode({
						ephemeralToken,
						values,
					} as LoginWithCodeRequest);
					actions.setSubmitting(false);
				}}
				schema={schema}
			>
				{(formikProps: FormikProps<FormValues>) => {
					const { isSubmitting, isValid, dirty, values, setFieldValue, setFieldTouched } =
						formikProps;
					return (
						<>
							{isRecoveryCodeLogin ? (
								<>
									<InputField
										label='Enter your recovery code'
										name='code'
										maxLength={codeLength}
										className='mt-8 md:w-[288px]'
										handleChange={() => setIsVerificationError(false)}
									/>
									<Typography className='text-secondaryTextDark pt-4'>
										All back up codes are for one time use only. Once you use a
										backup code to recover your account, that code becomes
										inactive.
									</Typography>
									<Button
										variant='secondary'
										type='button'
										onClick={() => {
											setIsVerificationError(false);
											setFieldValue('code', '');
											setFieldTouched('code', false);
											setIsRecoveryCodeLogin(false);
										}}
										size='lg'
										className='mt-4 mr-8 float-left'
									>
										Back
									</Button>
								</>
							) : (
								<>
									<VerificationCodeField
										name='code'
										maxLength={codeLength}
										values={values}
										setIsVerificationError={setIsVerificationError}
									/>
								</>
							)}
							<Button
								variant='primary'
								type='submit'
								isLoading={isSubmitting}
								disabled={!isValid || !dirty || isSubmitting}
								size='lg'
								className='mt-4'
							>
								Log in
							</Button>
							<ResendCode
								verifyError={isError}
								setIsVerificationError={setIsVerificationError}
								setCodeResentConfirm={setCodeResentConfirm}
								resendMutation={useResendCodeMutation}
								requestProps={{ ephemeralToken }}
								isRecoveryCodeLogin={isRecoveryCodeLogin}
							/>
							<CollapsibleSectionContainer
								isEditable={false}
								title='Need help?'
								classNames='mt-8 !p-0 bg-transparent'
								expandedOnLoad={false}
								showBorder={false}
								isFullWidth={false}
								titleClassNames='!text-primaryBrand'
							>
								<Typography variant='bodyBig' className='pb-4'>
									{!isRecoveryCodeLogin && (
										<>
											If you’re unable to access your account due to losing
											your primary MFA method, you can try logging in using
											your
											<Button
												variant='primaryTransparent'
												className='text-md !inline !p-0 !pl-1'
												onClick={() => {
													setIsVerificationError(false);
													setCodeResentConfirm(false);
													setFieldValue('code', '');
													setFieldTouched('code', false);
													setIsRecoveryCodeLogin(true);
												}}
											>
												recovery code
											</Button>
											.<br />
										</>
									)}
									If you don’t have your recovery code or need further assistance,
									please contact us at{' '}
									<a
										href='mailto:support@umed.io'
										className='text-primaryBrand underline'
									>
										support@umed.io
									</a>
									.
								</Typography>
							</CollapsibleSectionContainer>
						</>
					);
				}}
			</Form>
		</>
	);
};
