import { Spinner, Typography } from '@@/components/Elements';
import { useCubeQuery } from '@cubejs-client/react';
import React from 'react';
import {
	CartesianGrid,
	PieChart,
	Pie,
	Cell,
	AreaChart,
	Area,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	Legend,
	BarChart,
	Bar,
	LineChart,
	Line,
} from 'recharts';

import {
	CartesianChartProps,
	TypeToChartComponentType,
	TypeToChartComponentProps,
	renderChartComponentFunctionType,
	renderChartComponentType,
	ChartRendererProps,
} from '../../types';

const CartesianChart = ({ resultSet, children, ChartComponent }: CartesianChartProps) => (
	<ResponsiveContainer width='100%' height={350}>
		<ChartComponent data={resultSet.chartPivot()}>
			<XAxis dataKey='x' />
			<YAxis />
			<CartesianGrid />
			{children}
			<Legend />
			<Tooltip />
		</ChartComponent>
	</ResponsiveContainer>
);
const colors = ['#FF6492', '#141446', '#7A77FF'];

const TypeToChartComponent: TypeToChartComponentType = {
	line: ({ resultSet }: TypeToChartComponentProps) => {
		return (
			<CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
				{resultSet.seriesNames().map((series, i) => (
					<Line
						key={series.key}
						dataKey={series.key}
						name={series.title}
						stroke={colors[i]}
					/>
				))}
			</CartesianChart>
		);
	},
	bar: ({ resultSet }: TypeToChartComponentProps) => {
		return (
			<CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
				{resultSet.seriesNames().map((series, i) => (
					<Bar
						key={series.key}
						stackId='a'
						dataKey={series.key}
						name={series.title}
						fill={colors[i]}
					/>
				))}
			</CartesianChart>
		);
	},
	area: ({ resultSet }: TypeToChartComponentProps) => {
		return (
			<CartesianChart resultSet={resultSet} ChartComponent={AreaChart}>
				{resultSet.seriesNames().map((series, i) => (
					<Area
						key={series.key}
						stackId='a'
						dataKey={series.key}
						name={series.title}
						stroke={colors[i]}
						fill={colors[i]}
					/>
				))}
			</CartesianChart>
		);
	},
	pie: ({ resultSet }: TypeToChartComponentProps) => {
		return (
			<ResponsiveContainer width='100%' height={350}>
				<PieChart>
					<Pie
						isAnimationActive={false}
						data={resultSet.chartPivot()}
						nameKey='x'
						dataKey={resultSet.seriesNames()[0].key}
						fill='#8884d8'
					>
						{resultSet.chartPivot().map((e, index) => (
							<Cell key={index} fill={colors[index % colors.length]} />
						))}
					</Pie>
					<Legend />
					<Tooltip />
				</PieChart>
			</ResponsiveContainer>
		);
	},
	number: ({ resultSet }: TypeToChartComponentProps) => {
		return (
			<Typography variant='title4'>
				{resultSet.seriesNames().map((s) => resultSet.totalRow()[s.key])}
			</Typography>
		);
	},
	table: ({ resultSet }: TypeToChartComponentProps) => {
		return (
			<table className='mt-5 text-base'>
				<thead>
					<tr className='bg-secondaryBackground text-secondaryTextDark'>
						{resultSet.tableColumns().map((c) => (
							<th className='text-left py-3 px-2' key={c.key}>
								{c.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{resultSet.tablePivot().map((row, index) => (
						<tr key={index} className='border-b border-secondaryBorder'>
							{resultSet.tableColumns().map((c) => (
								<td className='py-3 px-2' key={c.key}>
									{row[c.key]}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		);
	},
};

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
	.map((key) => ({
		[key]: React.memo(TypeToChartComponent[key]),
	}))
	.reduce((a, b) => ({
		...a,
		...b,
	}));

const renderChart = (Component: renderChartComponentFunctionType) =>
	function renderChartComponent(props: renderChartComponentType) {
		const { resultSet, error } = props;
		return (
			(resultSet && <Component resultSet={resultSet} />) ||
			(error && error.toString()) || <Spinner />
		);
	};

const ChartRenderer = (props: ChartRendererProps) => {
	const { chartQueryData, cubeApi } = props;
	const { query, chartType } = chartQueryData;
	const component = TypeToMemoChartComponent[chartType];
	const renderProps = useCubeQuery(query, { cubeApi: cubeApi });
	return (
		<>
			{component &&
				renderChart(component)({
					resultSet: renderProps.resultSet,
					error: renderProps.error,
				})}
		</>
	);
};
export default ChartRenderer;
