import { getButtonClass } from '@@/components/Elements/Button';
import {
	endOfDay,
	format,
	isFuture,
	isPast,
	isSameDay,
	isValid,
	lastDayOfMonth,
	parse,
	startOfDay,
} from 'date-fns';
import { Converter } from 'showdown';
import {
	Serializer,
	Model,
	Question,
	FunctionFactory,
	ISurvey,
	ComponentCollection,
	ValidateQuestionEvent,
	AfterRenderQuestionEvent,
	TextMarkdownEvent,
	ValueChangedEvent,
	slk,
} from 'survey-core';
import * as SurveyCore from 'survey-core';
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react';
import { Survey } from 'survey-react-ui';
import { nouislider } from 'surveyjs-widgets';

import 'nouislider/distribute/nouislider.css';
import { SURVEY_JS_KEY } from '@/globalConsts';

nouislider(SurveyCore);

// Set surveyJS licence key
slk(SURVEY_JS_KEY);

export const SurveyTypes = {
	ScreeningAndConsent: 'screening',
	Questionnaire: 'questionnaire',
	ManualScreening: 'manual_screening',
};

export const QuestionTypes = {
	screening: 'screening',
	consent: 'consent',
	other: 'other',
};

const SurveyCustomProperties = ['consent', 'eligibility'];

export const getSurveyAnswers = (answer: { [key: string]: string } | null) => {
	// remove dynamic values from the answers data (anything defined in surveySetup).
	// they're not needed as are generated dynamically but if included here they don't get updated when answers change
	return answer
		? Object.keys(answer)
				.filter((key) => !SurveyCustomProperties.includes(key))
				.reduce<{ [index: string]: string | { [key: string]: string } }>(
					(surveyAnswers, currKey) => (
						(surveyAnswers[currKey] = answer[currKey]), surveyAnswers
					),
					{}
				)
		: {};
};

export const customSurveyCssClasses = {
	navigationButton: 'cursor-pointer',
	bodyNavigationButton: '',
	root: '',
	navigation: {
		complete: `btn--complete btn--proceed pr-10 ${getButtonClass({
			size: 'lg',
			variant: 'primary',
		})}`,
		prev: `btn--previous ${getButtonClass({ size: 'lg', variant: 'secondary' })}`,
		next: `btn--next btn--proceed pr-10 ${getButtonClass({ size: 'lg', variant: 'primary' })}`,
	},
	page: {
		root: '',
	},
	question: {
		number: 'font-normal',
		title: 'font-normal',
	},
	error: 'sd-error bg-transparent',
	radiogroup: {
		item: 'sd-item sd-radio sd-selectbase__item sd--radio-group',
	},
};

/**
 * Get Consent value based on answers of 'consent' questions
 * returns:
 *  0 if there are no consent questions in the survey
 *  0 if any of the questions has not been answered with 'consentAnswer'
 *  1 if all 'consent' questions have been answered with 'consentAnswer'
 * @returns 1 or 0
 */
function getConsent(this: { survey: Model }) {
	const consentQuestions = this.survey
		.getAllQuestions(true)
		.filter((q) => (q as Question)?.questionType === QuestionTypes.consent);

	return consentQuestions.length &&
		consentQuestions.every(
			(q) => JSON.stringify(q.value) === JSON.stringify((q as Question).consentAnswer)
		)
		? 1
		: 0;
}

/**
 * Get Eligibility value based on answers of 'screening' questions
 * returns:
 *  1 if all questions have been answered according to the 'eligibleAnswer',
 *  2 if all questions have been answered with 'eligibleAnswer' or 'possiblyEligibleAnswer'
 *  0 otherwise (espectially, if there are no 'screening' questions in the survey)
 */
function getEligibility(this: { survey: Model }) {
	const screeningQuestions = this.survey
		.getAllQuestions(true)
		.filter((q) => (q as Question)?.questionType === QuestionTypes.screening);
	if (!screeningQuestions.length) return 0;
	else if (
		screeningQuestions.every((q) => {
			const res = JSON.stringify(q.value) === JSON.stringify((q as Question)?.eligibleAnswer);
			return res;
		})
	)
		return 1;
	else if (
		screeningQuestions.every(
			(q) =>
				JSON.stringify(q.value) === JSON.stringify((q as Question)?.eligibleAnswer) ||
				JSON.stringify(q.value) === JSON.stringify((q as Question)?.possiblyEligibleAnswer)
		)
	)
		return 2;
	return 0;
}

// add survey object properties
Serializer.addProperty('survey', {
	name: 'surveyType',
	readOnly: true,
	choices: [
		SurveyTypes.ScreeningAndConsent,
		SurveyTypes.Questionnaire,
		SurveyTypes.ManualScreening,
		SurveyTypes.Questionnaire,
	],
	category: 'general',
});
interface SurveyWithCustom extends ISurvey {
	surveyType?: string;
}
interface QuestionWithCustom extends Question {
	survey: SurveyWithCustom;
}

// Add survey properties
Serializer.addProperty('survey', {
	name: 'showSurveyLogo',
	displayName: 'Show survey logo?',
	type: 'boolean',
	default: true,
	category: 'general',
	visibleIndex: 0,
});

Serializer.addProperty('survey', {
	name: 'selectSurveyLogo',
	displayName: 'Select survey logo',
	default: 'uMed',
	choices: ['uMed', 'AccessPD', 'AccessILD', 'AccessCMD'],
	category: 'general',
	dependsOn: ['showSurveyLogo'],
	visibleIndex: 1,
	visibleIf: function (obj: Question) {
		return obj.showSurveyLogo === true;
	},
});

Serializer.addProperty('survey', {
	name: 'showContactDetails',
	displayName: 'Show contact details?',
	type: 'boolean',
	default: true,
	category: 'general',
	visibleIndex: 2,
});

Serializer.addProperty('survey', {
	name: 'showConfirmationOnComplete',
	displayName: 'Show confirmation screen on completion?',
	type: 'boolean',
	default: false,
	category: 'general',
	visibleIndex: 3,
});

//Add Question properties
Serializer.addProperty('question', {
	name: 'questionType',
	choices: (q: QuestionWithCustom) => {
		// allow to choose screening and consent question type only for screeening and consent surveys
		if (q.survey.surveyType === SurveyTypes.ScreeningAndConsent)
			return [QuestionTypes.consent, QuestionTypes.screening, QuestionTypes.other];
		else if (q.survey.surveyType === SurveyTypes.ManualScreening)
			return [QuestionTypes.screening, QuestionTypes.other];
		else return [QuestionTypes.other];
	},

	default: QuestionTypes.other,
	category: 'general',
});

Serializer.addProperty('question', {
	name: 'consentAnswer:value',
	dependsOn: ['questionType'],
	visibleIf: function (obj: Question) {
		return obj.questionType === QuestionTypes.consent;
	},
	category: 'general',
});

Serializer.addProperty('question', {
	name: 'eligibleAnswer:value',
	dependsOn: ['questionType'],
	visibleIf: function (obj: Question) {
		return obj.questionType === QuestionTypes.screening;
	},
	category: 'general',
});

Serializer.addProperty('question', {
	name: 'possiblyEligibleAnswer:value',
	dependsOn: ['questionType'],
	visibleIf: function (obj: Question) {
		return obj.questionType === QuestionTypes.screening;
	},
	category: 'general',
});

Serializer.addProperty('question', {
	name: 'allowPastDates',
	displayName: 'Allow past dates',
	type: 'boolean',
	default: true,
	category: 'validation',
	visibleIf: function (obj: Question) {
		return obj.inputType === 'date' || obj.inputType === 'month';
	},
});

Serializer.addProperty('question', {
	name: 'allowFutureDates',
	displayName: 'Allow future dates',
	type: 'boolean',
	default: true,
	category: 'validation',
	visibleIf: function (obj: Question) {
		return obj.inputType === 'date' || obj.inputType === 'month';
	},
});

Serializer.addProperty('question', {
	name: 'allowOnlyToday',
	displayName: "Allow only today's date",
	type: 'boolean',
	default: true,
	category: 'validation',
	visibleIf: function (obj: Question) {
		return obj.inputType === 'date';
	},
});

Serializer.addProperty('question', {
	name: 'isHealthScale',
	displayName: 'Is health scale?',
	type: 'boolean',
	default: true,
	category: 'slider',
	visibleIndex: 12,
	visibleIf: function (obj: Question) {
		return obj.getType() === 'nouislider';
	},
});

Serializer.addProperty('question', {
	name: 'crossValidatedWith',
	displayName: 'If answer changed then also validate field',
	type: 'string',
	category: 'validation',
});

FunctionFactory.Instance.register('getConsent', getConsent);
FunctionFactory.Instance.register('getEligibility', getEligibility);

// Add predefined question types
ComponentCollection.Instance.add({
	name: 'eligible_yes_no',
	// The text that shows on toolbox
	title: 'Eligible Yes/No Question',
	//The actual question that will do the job
	questionJSON: {
		type: 'radiogroup',
		choices: [
			{
				value: 'yes',
				text: 'Yes',
			},
			{
				value: 'no',
				text: 'No',
			},
		],
	},
	onCreated(question: QuestionWithCustom) {
		// set defaults
		question.questionType = 'screening';
		question.isRequired = true;
		question.eligibleAnswer = 'yes';
	},
});
ComponentCollection.Instance.add({
	name: 'posibly_eligible_yes_no',
	// The text that shows on toolbox
	title: 'Posibly Eligible Yes/No Question',
	// The actual question that will do the job
	questionJSON: {
		type: 'radiogroup',
		choices: [
			{
				value: 'yes',
				text: 'Yes',
			},
			{
				value: 'no',
				text: 'No',
			},
		],
	},
	onCreated(question: QuestionWithCustom) {
		// set defaults
		question.isRequired = true;
		question.questionType = 'screening';
		question.eligibleAnswer = 'yes';
		question.possiblyEligibleAnswer = 'no';
	},
});

ComponentCollection.Instance.add({
	name: 'consent',
	// The text that shows on toolbox
	title: 'Consent Yes/No Question',
	// The actual question that will do the job
	questionJSON: {
		type: 'radiogroup',
		questionType: 'consent',
		choices: [
			{
				value: 'yes',
				text: 'Yes',
			},
			{
				value: 'no',
				text: 'No',
			},
		],
	},
	onCreated(question: QuestionWithCustom) {
		// set defaults
		question.isRequired = true;
		question.questionType = 'consent';
		question.consentAnswer = 'yes';
	},
});

const dateFormat = 'y-MM-dd'; // YYYY-MM-DD
const dateMonthFormat = 'y-MM'; // YYYY-MM
export const customValidation = (sender: Model, options: ValidateQuestionEvent) => {
	const question = options.question;
	if (question.inputType === 'date' || question.inputType === 'month') {
		const value = question.inputType === 'date' ? options.value : `${options.value}-01`;
		const date = parse(value, dateFormat, new Date());
		if (isValid(date)) {
			const allowPastDates = question.allowPastDates;
			if (!allowPastDates) {
				// check that date is not in the past
				// if date type is month then we need to get the last day of the month for this check
				const dateToCheck = question.inputType === 'date' ? date : lastDayOfMonth(date);
				if (isPast(endOfDay(dateToCheck))) {
					options.error = 'Date should not be in the past';
				}
			}
			const allowFutureDates = question.allowFutureDates;
			if (!allowFutureDates) {
				// check that date is not in the future
				if (isFuture(startOfDay(date))) {
					options.error = 'Date should not be in the future';
				}
			}
			const allowOnlyToday = question.allowOnlyToday;
			if (question.inputType === 'date' && allowOnlyToday) {
				// check that date selected is todays date
				if (!isSameDay(startOfDay(date), startOfDay(new Date()))) {
					options.error = "Date selected should be today's date";
				}
			}
		} else {
			options.error = 'Date should be a valid date';
		}
	}
};

const formatTooltip = (questionContainer: HTMLElement) => {
	// noUiToolitp by default displays the tooltip value with two decimal places
	// This updates the HTML to just show the value with no decimal places.
	const tooltip = questionContainer.getElementsByClassName('noUi-tooltip')[0];
	if (tooltip) {
		const toolTipValue = parseInt(tooltip.innerHTML);
		if (!isNaN(toolTipValue)) {
			tooltip.innerHTML = `${toolTipValue}`;
		}
	}
};

const tooltipWatcher = (questionContainer: HTMLElement) => {
	// observe for changes on the tooltip handle to apply custom formatting
	if (questionContainer) {
		const targetNode = questionContainer.getElementsByClassName('noUi-handle')[0];
		// only need to observe the data attribute
		const config = {
			subtree: false,
			childList: false,
			attributes: true,
			attributeFilter: ['aria-valuenow'], // filter value
		};
		// callback function to execute when changes are detected
		const callback = (mutationList: MutationRecord[]) => {
			formatTooltip(mutationList[0].target as HTMLElement);
		};
		// new MutationObserver linked to the callback function
		const observer = new MutationObserver(callback);
		// start the observing on the target node
		observer.observe(targetNode, config);
	}
};

const injectSliderElements = (
	questionContainer: HTMLElement,
	questionHeaderContainer: Element | null,
	questionValue: string,
	elementId: string
) => {
	// this function creates the custom elements for the health scale slider
	// use data attribute for styling rather than class as SurveyJS can override class name
	questionContainer.setAttribute('data-question', 'health-scale');
	// create health summary elements
	const valueContainerDiv = document.createElement('div');
	valueContainerDiv.className = 'slider__health-summary-container';
	const valueText = document.createElement('p');
	valueText.innerHTML = 'Your health today is: ';
	const valueSpan = document.createElement('span');
	valueSpan.id = `js-${elementId}-sliderValue`;
	valueSpan.className = 'slider__health-summary-value';
	valueSpan.innerHTML = questionValue || '?';
	valueText.appendChild(valueSpan);
	valueContainerDiv.appendChild(valueText);
	// add health summary elements to question
	if (questionHeaderContainer) {
		questionHeaderContainer.appendChild(valueContainerDiv);
	}
	const questionInputContainer = questionContainer.querySelector('.sd-question__content');
	if (questionInputContainer) {
		// create slider scale elements
		const highestText = document.createElement('p');
		highestText.className = 'slider-label slider-label--top';
		highestText.innerHTML = 'The best health you can imagine';
		const lowestText = document.createElement('p');
		lowestText.className = 'slider-label slider-label--bottom';
		lowestText.innerHTML = 'The worst health you can imagine';
		// add slider scale elements to slider
		questionInputContainer.prepend(highestText);
		questionInputContainer.appendChild(lowestText);
	}
};

export const customQuestionAfterRender = (_sender: Model, options: AfterRenderQuestionEvent) => {
	const question = options.question;
	const type = question.getType();
	if (question.inputType === 'date' || question.inputType === 'month') {
		// set date input attributes
		const input = options.htmlElement.getElementsByTagName('input')[0];
		const todayDate = format(
			new Date(),
			question.inputType === 'date' ? dateFormat : dateMonthFormat
		);
		const allowPastDates = question.allowPastDates;
		if (!allowPastDates) {
			input.setAttribute('min', todayDate);
		}
		const allowFutureDates = question.allowFutureDates;
		if (!allowFutureDates) {
			input.setAttribute('max', todayDate);
		}
	} else if (type === 'nouislider') {
		const elementId = question.id;
		const questionContainer = document.getElementById(elementId);
		if (questionContainer) {
			formatTooltip(questionContainer);
			tooltipWatcher(questionContainer);
			const isHealthScale = question.isHealthScale;
			if (isHealthScale) {
				const questionHeaderContainer =
					options.htmlElement.querySelector('.sd-question__header');
				const questionValue = question.value;
				injectSliderElements(
					questionContainer,
					questionHeaderContainer,
					questionValue,
					elementId
				);
			}
		}
	}
};

export const customTextMarkdown = (_survey: Model, options: TextMarkdownEvent) => {
	// Adds HTML support for question descriptions and titles
	const type = options.element.getType();
	// Only required for nouislider question types
	if (type === 'nouislider') {
		const converter = new Converter();
		// convert the markdown text to html
		const str = converter.makeHtml(options.text);
		// set HTML
		options.html = str;
	}
};

export const customValueChanged = (_result: Model, options: ValueChangedEvent, survey: Model) => {
	const type = options.question.getType();
	if (type === 'nouislider') {
		// update the score summary element
		const elementId = options.question.id;
		const valueSpanId = `js-${elementId}-sliderValue`;
		const valueElement = document.getElementById(valueSpanId);
		if (valueElement) valueElement.innerHTML = options.value;
	}
	if (options.question.crossValidatedWith) {
		// revalidate questions that are linked to this question to avoid confusing error messages
		const linkedQuestion = survey.getQuestionByName(options.question.crossValidatedWith);
		if (linkedQuestion !== undefined && linkedQuestion.value?.length >= 1) {
			// trigger validation for the linked question
			linkedQuestion.hasErrors(true, false);
		}
	}
};

export { Model as SurveyModel };
export { Survey as SurveyObject };
export { SurveyCreator };
export { SurveyCreatorComponent };
